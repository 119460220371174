<template>
   <div class="wrapper">
      <div class="s_box">
        <div>
          <van-icon name="arrow-left" :color='"#313131"' class='backicon' :size='"26px"' @click='back' />
        </div>
           <div class="search_box">
             <div class="search_main">
               <img src="https://static.yihu365.cn/img/h5Img/shop/search.png" alt="">
               <input type="text" placeholder="请输入商品名称" v-model="inputValue" autofocus>
               <span class="animate__animated animate__headShake" @click="search()">搜索</span>
             </div>
           </div>
      </div>
     <div class='header_cont'></div>
     <div class="none_box" v-if="noMessage">
       <img src="https://static.yihu365.cn/img/h5Img/shop/no_search.png" alt="">
       <div>暂无搜索消息噢~</div>
     </div>
     <div class="lists" v-if="!noMessage">
       <div class="item" v-for="(item,index) in mallList" >
         <!--         <img class="photo" :src="item.iconUrl" alt="" >-->
         <van-image
             width="90"
             height="90"
             :src="item.iconUrl"
             @click="toDetail(item)"
         />
         <div class="info">
           <div class="title" @click="toDetail(item)">{{item.name}}</div>
           <div class="p_box">
             <div class="price">¥<span>{{item.price}}</span></div>
             <div class="tb" @click="addCart(item)"><img src="https://static.yihu365.cn/img/h5Img/shop/add_cart.png" alt=""></div>

           </div>
         </div>
       </div>
     </div>
     <div class="like_txt">猜你喜欢</div>
     <div class="lists mt0">
       <div class="item" v-for="(item,index) in likeList" >
<!--         <img class="photo" :src="item.iconUrl" alt="" >-->
         <van-image
             width="90"
             height="90"
             :src="item.iconUrl"
             @click="toDetail(item)"
         />
         <div class="info">
           <div class="title" @click="toDetail(item)">{{item.name}}</div>
           <div class="p_box">
             <div class="price">¥<span>{{item.price}}</span></div>
             <div class="tb magictime" :class="{'tinDownOut':isActive ==index}" @click="addCart(item,index)"><img src="https://static.yihu365.cn/img/h5Img/shop/add_cart.png" alt=""></div>

           </div>
         </div>
       </div>
     </div>
<!--     购物车-->
     <div class="cart_box animate__animated animate__fadeInBottomRight">
       <van-badge :content="cartInfo.goodsSum" @click="toCart">
         <div class="cart">
           <img src="https://static.yihu365.cn/img/h5Img/shop/cart_4.png" alt="">
         </div>

       </van-badge>
     </div>
   </div>
</template>

<script>
import {setWxShareDate} from '@/libs/utils.js'
import { onMounted, ref, computed,reactive } from "vue"
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { Dialog , Toast} from 'vant'
import shopApi from '@axios/shop'
import { channel } from '@/config/channel.js';
export default {
  name: "searchShop",
  setup(){
    let router = useRouter();
    const store = useStore();
    const route = useRoute();
    const flag = ref(false)
    const inputValue = ref('')
    const mallList= ref('')
    const noMessage = ref(true);
    const search =() => {
       flag.value = !flag.value;
       if(!inputValue.value){
         Toast('请输入商品名称哦')
         return;
       }
       const params ={
         keyword:inputValue.value,
         pageNum:1,
         pageSize:20,
       }
       shopApi.getMallSearch(params).then((res)=> {
         console.log(res)
         mallList.value = res.data.pageData;
         if(mallList.value.length == 0){
             noMessage.value = true;
         }else{
           noMessage.value = false;
         }
       })

    }
    const likeList = ref('')
    const getYouLike =()=> {
        const params ={
           userId:store.state.uid
        }
        shopApi.getGoodsYouLike(params).then((res)=> {
          console.log('like',res)
          likeList.value = res.data.pageData;
        })
    }
    getYouLike();


    //cart  add
    const isActive = ref('-1')//动画开关
    const addCart = (item,index) => {
      isActive.value = index
      const params = {
        userId : store.state.uid,
        synchronization:[{
          goodsId : item.id,
          goodsNum : 1,
          type : 3 ,
          unitPrice : item.price,
          goodsName: item.name,
          iconUrl : item.iconUrl,
        }]

      }
      shopApi. setSynchronization(params).then((res)=> {
        console.log('Addcart',res)
        cartInfo.value = res.data;
        Toast('已加入购物车')
        getCartNums()

      })
    }
    // Cart  nums
    const cartInfo = ref('')
    const getCartNums = () => {
      const params = {
        userId : store.state.uid
      }
      shopApi.getCartInfos(params).then((res)=> {
        console.log('cart',res)
        cartInfo.value = res.data;
      })
    }
    getCartNums()

    //跳转
    const  toCart = () => { //          购物车
      router.push({
        name: 'cart',
        query: {

        }
      })
    }

    const toDetail=(item)=> {
      router.push({
        name: 'shopDetail',
        query: {
          goodsId : item.id
        }
      })
    }
    const back = ()=> {
      router.back()
    }
    return {
      back,
      inputValue,
      search,
      flag,
      mallList,
      getYouLike,
      likeList,
      noMessage,
      getCartNums,
      cartInfo,
      addCart,
      toDetail,
      toCart,
      isActive
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper{
  box-sizing: border-box;
  padding-bottom: 40px;
  min-height:100vh;
  background-color: #f2f3f4;
}
.s_box{
  width: 100%;
  position: fixed;
  left:0;
  top:0;
  z-index: 100;
  height:120px;
  background-color: #FFFFFF;
  border-bottom: 1px solid #F5F5F5;
  display: flex;
  align-items: center;
  .backicon{
    margin:0 20px 0 10px;
  }
}
.search_box{
  width:82%;
  .search_main{
    box-sizing: border-box;
    padding:4px;
    display: flex;
    align-items: center;
    border:3px solid #00c291;
    border-radius: 60px;
    justify-content: space-between;
    img{
      width: 36px;
      height: 36px;
      margin-left: 8px;
      margin-right: 8px;
    }
    input{
      color:#666;
      caret-color: #999999;
      font-size: 30px;
      box-sizing: border-box;
      padding-left: 4px;
      border:none;
      height:45px;
      flex-grow: 1;
    }
    ::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      font-size: 30px;
      color:#999;
    }
    :-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      font-size: 30px;
      color:#999;
    }
    ::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      font-size: 30px;
      color:#999;
    }
    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      font-size: 30px;
      color:#999;
    }
    span{
      display: block;
      margin-left: 10px;
      text-align: center;
      //width: 120px;
      background: #00c291;
      color:#FFFFFF;
      font-size: 32px;
      padding:10px 20px;
      box-sizing: border-box;
      border-radius: 40px;
      white-space: nowrap;
    }
  }
}
.mt0{
  margin-top: 20px!important;
}
.lists{
  box-sizing:border-box;
  ::v-deep(.van-sidebar-item){
    font-size: 32px;
  }
  margin:60px auto 20px;
  background-color: #fff;
  width:92%;
  border-radius: 20px;
  flex-grow: 1;
  padding:20px 10px;
  .item{
    display: flex;
    box-sizing: border-box;
    padding:0px 20px 30px 6px;
    //border-bottom: 1px solid #e5e5e5;
    .photo{
      width: 180px;
      height: 180px;
      margin-right: 20px;
      border:1px solid #999999;
      //box-shadow: 0 0 20px #ebedf;
    }
    .info{
      flex-grow: 1;
      font-size: 26px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 18px;
      .title{
        margin-top: 10px;
        text-overflow: ellipsis;
        font-size: 30px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        display: -moz-box;
        -moz-line-clamp: 2;
        -moz-box-orient: vertical;

        overflow-wrap: break-word;
        word-break: break-all;
        white-space: normal;
        overflow: hidden;

      }
      .price{
        font-size: 30px;
        color:#e4393c;
        span{
          font-size: 38px;
        }
      }
    }
    .p_box{
      display: flex;
      justify-content: space-between;
      .price{
        flex-grow: 1;
      }
      .tb{
        img{
          width: 50px;
          height: 50px;
          margin-right: 20px;
        }
      }
    }
  }
}
.like_txt{
  padding-top: 20px;
  font-size: 38px;
  box-sizing: border-box;
  padding-left: 38px;
  color: #00c291;
  font-weight: 500;
}
.none_box{
  width: 100%;
  height:420px;
  text-align: center;
  img{
    width: 50%;
  }
  color:#999999;
  font-size: 28px;
}


.cart_box{
  position: fixed;
  right: 80px;
  bottom:80px;
  z-index: 1;
  ::v-deep(.van-badge){
    background: #00c291;
    border-color:#00c291;
  }
  ::v-deep(.van-badge--fixed){
    top:20px;
    right:16px;
  }
}
.cart{
  img{
    margin-top: 15px;
    width: 72px;
    height: 72px;

  }
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #FFFFFF;
  box-shadow:0 0 400px 1px #00c291;
  text-align: center;


}
</style>
