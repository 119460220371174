import { render } from "./search.vue?vue&type=template&id=501f9c33&scoped=true"
import script from "./search.vue?vue&type=script&lang=js"
export * from "./search.vue?vue&type=script&lang=js"

import "./search.vue?vue&type=style&index=0&id=501f9c33&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-501f9c33"
/* hot reload */
if (module.hot) {
  script.__hmrId = "501f9c33"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('501f9c33', script)) {
    api.reload('501f9c33', script)
  }
  
  module.hot.accept("./search.vue?vue&type=template&id=501f9c33&scoped=true", () => {
    api.rerender('501f9c33', render)
  })

}

script.__file = "src/views/shop/classify/search/search.vue"

export default script